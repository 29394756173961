import * as React from 'react';

import SidePanel from "Components/SidePanel/SidePanel";
import TopBar from "Components/TopBar/TopBar";
import Modal from 'Components/Modal/Modal';
import { modalScreensEnum } from 'Libraries/Enums';
import ChangePasswordScreen from 'Screens/ChangePassword/Content';
import { IRootReducer } from "Libraries/State.Interfaces";
import { useSelector } from 'react-redux';
import { sideBarONOFFAction } from 'Redux/Action';
import { Images } from 'Libraries/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { Paths } from 'Libraries/Route';
import {APIProvider} from '@vis.gl/react-google-maps';

export default function MasterLayout(props: { 
    children: JSX.Element | any; isReportTab?: boolean; 
    dispatch: React.Dispatch<AnyAction>
    userEmail: string;
}): JSX.Element {

    const navigate: any = useNavigate();
    const { state, pathname, search } = useLocation();

    const { loader, sideBarState: open } = useSelector((state: IRootReducer) => state.activityReducer);

    const [mainTitle, setMainTitle] = React.useState<string>(document.title);
    const [openModal, setOpenModal] = React.useState<{ flag: boolean, key: string }>({flag: false, key: ''});

    return(
        <React.Fragment>
            <SidePanel 
                isProfile={openModal.key === modalScreensEnum[modalScreensEnum.profile]} 
                isSideBarOpen={open} setOpen={(f: boolean) => {
                    setOpenModal({flag: false, key: ''});
                    props.dispatch(sideBarONOFFAction(f));
                }}
            />
            <div className={`flex flex-1 bg-[#F5F5F5] flex-col ${open ? 'md:pl-60 lg:pl-60 xl:pl-60' : 'md:pl-20 lg:pl-20 xl:pl-20'} h-screen relative`}>
                <TopBar isReportTab={state?.isReportTab} isSideBarOpen={open} userEmail={props.userEmail ?? ''} setOpenModal={(flag, key) => {
                    setOpenModal({flag, key});
                        if (key === modalScreensEnum[modalScreensEnum.profile]) {
                            props.dispatch(sideBarONOFFAction(true));
                        }
                    }} 
                    goToBack={() => navigate(-1)} mainTitle={mainTitle} isDisplayReportDescription={state?.reportDescription as string}
                    onNavigate={() => {
                        navigate(`${Paths.home}/${pathname.split('/')[1]}${search}`, { state: { ...state, isReportTab: false } });
                    }}
                />
                <main className={`remove-scrollbars ${state?.isReportTab ? "py-2 overflow-auto" : "py-4 overflow-hidden"}`}>
                    <div className='w-full h-16 invisible'>_</div>
                    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY as string}>
                        {props.children({
                            setMainTitle
                        })}
                    </APIProvider>
                </main>
            </div>

            {openModal.flag &&
                <Modal open={openModal.flag && openModal.key === modalScreensEnum[modalScreensEnum.password]} setOpen={(e: boolean)=> setOpenModal({ ...openModal, flag: e })}
                        isSmall={openModal.key === modalScreensEnum[modalScreensEnum.password]}>
                    { openModal.key === modalScreensEnum[modalScreensEnum.password] ?
                        <ChangePasswordScreen setOpen={(e: boolean)=> setOpenModal({ ...openModal, flag: e })} />
                    : null }
                </Modal>
            }

            {loader && (
                <div className="loader app-preloader">
                    <img alt="wizardlogo" src={Images.logo} width={90} height={90} />
                </div>
            )}
        </React.Fragment>
    );
}
