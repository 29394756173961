import { ICommentItem, ICompany, ICompanyInvoice, IDashboardCardData, IEmailReply, IExposureFile, IHistoryItem, IPendingSubmissionAttachment, IPendingVersionListItem, IReportData, IReportedConstruction, IReportedOccupancy, IResponse, IStpListItem, ISubmissionListItem, IUserProfile, IVersionListItem, IVersionStatus } from "Libraries/Interfaces";
import ApiService from "./ApiService";
import ApiUrls from "./ApiUrls";
import { AxiosResponse } from "axios";

class Request{
    public async signUpRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.signUpUrl, data, false);
        } catch (error) {
            throw error;
        }
    };
 
    public async signInRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.loginUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async refreshTokenRequest(data: any): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.tokenRefreshUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async getSubmissionRequest(data: any, params: {[key: string]: any;}): Promise<AxiosResponse<{ results: ISubmissionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionUrl, data, params);
        } catch (error) {
            throw error;
        }
    };

    public async getASubmissionRequest(controlNumber: string): Promise<AxiosResponse<ISubmissionListItem, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionUrl+controlNumber, undefined, {});
        } catch (error) {
            throw error;
        }
    };

    public async getSubmissionVersionsRequest(params: {[key: string]: any;}): Promise<AxiosResponse<{ results: IVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionVersionsUrl, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getASubmissionVersionRequest(params: {[key: string]: any;}): Promise<AxiosResponse<{ results: IVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSubmissionVersionsUrl ,undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getPendingSubmissionVersions(params: {[key: string]: any;}): Promise<AxiosResponse<{ results: IPendingVersionListItem[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.pendingVersions, undefined, params);            
        } catch (error) {
            throw error;
        }
    };

    public async getPendingSubmissionVersionAttachments(params: {[key: string]: any;}): Promise<AxiosResponse<{ results: IPendingSubmissionAttachment[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.pendingVersionAttachments, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getCompaniesList(): Promise<AxiosResponse<{ results: ICompany[]; count: number; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.listCompanies, undefined, {});
        } catch (error) {
            throw error;
        }
    };

    public async getVersionComments(params: {[key: string]: any;}): Promise<AxiosResponse<ICommentItem[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.versionComments, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionHistories(params: {[key: string]: any;}): Promise<AxiosResponse<{ results: IHistoryItem[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.fetchHistory, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionExposureFiles(params: {[key: string]: any;}): Promise<AxiosResponse<IExposureFile[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.exposureFiles, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getVersionExposureTemplates(params: {[key: string]: any;}): Promise<AxiosResponse<{ response: { file_name: string; type: string; original_file_name?: string; }[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.exposureTemplates, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getDashboardCounts(params: {[key: string]: any;}): Promise<AxiosResponse<IDashboardCardData, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.dashboardCounts, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getReportedConstructions(params: {[key: string]: any;}): Promise<AxiosResponse<{ count: number; results: IReportedConstruction[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportedConstructions, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getReportedOccupancies(params: {[key: string]: any;}): Promise<AxiosResponse<{ count: number; results: IReportedOccupancy[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.reportedOccupancies, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async generateInvoice(params: {[key: string]: any;}): Promise<AxiosResponse<ICompanyInvoice | null, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.generateInvoice, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getCompanies(): Promise<AxiosResponse<{ company_names: string[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getCompanies, undefined, {});
        } catch (error) {
            throw error;
        }
    };

    public async approvePendingVersion(data: { pending_submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.approvePendingVersion, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async approveAsControlNumber(data: { pending_submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.approveAsControlNumber, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async addVersionComments(data: { comment: string; submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.versionComments, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async updateStatus(data: IVersionStatus): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.updateStatus, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async archiveSubmission(data: { control_number: string; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.archiveSubmission, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async dublicateVersion(data: { control_number: string; version: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.dublicateVersion, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async emailReplyList(params: {[key: string]: any;}): Promise<AxiosResponse<IEmailReply[], any>> {
        try {
            return await ApiService.getRequest(ApiUrls.emailReplyList, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async getDTReportJson(params: {[key: string]: any;}): Promise<AxiosResponse<{ data_transparency_report_json: IReportData; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getDTReportJson, undefined, params);
        } catch (error) {
            throw error;
        }
    };

    public async archiveVersion(data: { control_number: string; versions: number[]; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.archiveVersion, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async resetPasswordTryRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.resetPasswordRequestUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async resetPasswordConfirmRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.resetPasswordConfirmUrl, data, false);
        } catch (error) {
            throw error;
        }
    };

    public async createLogRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.createLogUrl, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async sendUWEmail(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequestWithMultiPart(ApiUrls.uwEmail, data);
        } catch (error) {
            throw error;
        }
    };

    public async changePasswordRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.changePasswordUrl, data);
        } catch (error) {
            throw error;
        }
    };

    public async submissionAssignRequest(data: { submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.submissionAssign, data);
        } catch (error) {
            throw error;
        }
    };

    public async submissionUpdateRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.submissionUpdate, data);
        } catch (error) {
            throw error;
        }
    };

    public async submissionGetRequest(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.submissionGetUpdate, data, {});
        } catch (error) {
            throw error;
        }
    };

    public async exportUserGetRquest(data: { control_number: string; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.exportTemplate, data, {});
        } catch (error) {
            throw error;
        }
    };

    public async getSTPSovsLists(data: any, params: {[key: string]: any;}): Promise<AxiosResponse<{ count: number; results: IStpListItem[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getSTPSovsLists, data, params);
        } catch (error) {
            throw error;
        }
    };

    public async getUserDetails(): Promise<AxiosResponse<IUserProfile | undefined, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getUserDetails, {}, {});
        } catch (error) {
            throw error;
        }
    };

    public async postSovForSTPProcess(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequestWithMultiPart(ApiUrls.postSovForSTPProcess, data);
        } catch (error) {
            throw error;
        }
    };

    public async getConstOccPieChartData(data: any): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.constOccPieChartData, data, true);
        } catch (error) {
            throw error;
        }
    };

    public async getPasswordRules(): Promise<AxiosResponse<{ password_rules: string[]; }, any>> {
        try {
            return await ApiService.getRequest(ApiUrls.getPasswordRules, undefined, {}, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportMapData(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportMapData, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportMapDataDetails(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportMapDataDetails, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportGeoCodeData(params: {[key: string]: any;}): Promise<AxiosResponse<{ data: { [key: string]: { count: number; percentage: number; } }}, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportGeoCodeData, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportConstructionsData(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportConstructionsData, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportOccupancyData(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportOccupancyData, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportCoverageData(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportCoverageData, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportDataQuality(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportDataQuality, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportDataCompleteness(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportDataCompleteness, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async reportDataCoverageByGeography(params: {[key: string]: any;}): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.postRequest(ApiUrls.reportDataCoverageByGeography, params, true);
        } catch (error) {
            throw error;
        }
    };

    public async submissionReviewerAssign(data: { submission_version_id: number; }): Promise<AxiosResponse<any, any>> {
        try {
            return await ApiService.putRequest(ApiUrls.submissionReviewerAssign, data);
        } catch (error) {
            throw error;
        }
    };

    public async updateVersionDescription(data: { control_number: string; version: number; description: string; }): Promise<AxiosResponse> {
        try {
            return await ApiService.postRequest(ApiUrls.updateVersionDescription, data, true);
        } catch (error) {
            throw error;
        }
    };
}

const ApiRequest = new Request();

export default ApiRequest;
