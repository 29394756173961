import * as React from 'react';

import { SpinnerLoader } from 'Components/Loader/Loader';
import Methods from 'Libraries/CommonMethodsUI';
import { ICommentItem } from 'Libraries/Interfaces';
import ApiRequest from 'Services/ApiRequest';
import { Tooltip } from 'react-tooltip';
import { MdOutlineErrorOutline } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";

const CommentItem: React.FC<{ commentItem: ICommentItem }> = ({ commentItem }) => {
    const c: string = commentItem.display_color;
    
    return (
        <div
            className="relative flex items-start space-x-3 mb-3 rounded-lg border border-[#D9E1E7] px-4 py-2 shadow-sm focus-within:ring-2"
        >
            <div className="shrink-0">
                <FaUserFriends className="size-5 hover:text-black transition-all duration-200 ease-in-out" color={c} />
            </div>
            <div className="min-w-0 flex-1 focus:outline-none">
                <span aria-hidden="true" className="absolute inset-0" />
                <p className="text-sm font-medium text-black mb-1 text-left">{commentItem.user}</p>
                <p className="text-xs text-[#9CA3AF] text-left"> { commentItem.comment } </p>
            </div>
            <div 
                className="shrink-0 cursor-pointer relative"
                data-tooltip-id="comment-item"
                data-tooltip-content={Methods.convertUTCtoLocalDate(commentItem.created_on)}
                data-tooltip-place="bottom-start"
            >
                <MdOutlineErrorOutline className="size-4 hover:text-black transition-all duration-200 ease-in-out text-[#9CA3AF]" />

                <Tooltip id="comment-item" arrowColor='#000' />
            </div>
        </div>
    );
}

export const CommentTab: React.FC<{ versionId: string | undefined; id: number | undefined; controlNumber: string; }> = ({ versionId, id, controlNumber }) => {

    const [isVisible, setIsVisible] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [text, setText] = React.useState<string>('');
    const [comments, setComments] = React.useState<ICommentItem[]>([]);

    const get = React.useCallback(async (flag: boolean) => {
        setLoading(flag);
        try {
            const res = await ApiRequest.getVersionComments({
                version: versionId, control_number: controlNumber
            });
            setComments(res.data);
            setLoading(false);
        }
        catch(e) {
            setLoading(false);            
        }
    }, [versionId, controlNumber]);

    React.useEffect(() => {
        if(versionId) {
            setText('');
            get(true);
        }
    }, [versionId, controlNumber, get]);

    const onSubmitComment = React.useCallback(async () => {
        if (text){
            try {
                const res = await ApiRequest.addVersionComments({ comment: text, submission_version_id: parseInt(id as any) });
                setComments([res.data, ...comments]);
                setText('');
            }
            catch (err) {}
        }
    }, [id, text, comments]);

    return (
        <div className={`${isVisible ? 'flex-1' : 'h-auto'} bg-white overflow-y-auto remove-scrollbar relative rounded-2xl`}>
            <div className="border-b border-[#D9E1E7] sticky top-0 z-10 backdrop-blur backdrop-filter bg-white bg-opacity-75 px-4">
                <span onClick={() => setIsVisible(!isVisible)} className='cursor-pointer text-xs sm:text-sm md:text-base lg:text-base text-[#0F172A] font-medium leading-6 py-3 block'>{'Comments'}</span>
            </div>

            <div className='px-4 pt-4 text-center h-full'>
                { loading ? (
                    <SpinnerLoader adjustment={true} enhance="text-black text-sm my-3" />
                ) : comments?.length === 0 ? (
                    <span className='text-sm text-black mb-4'>No comments available.</span>
                ) : isVisible ? comments?.map((item, i) => (
                    <CommentItem key={i} commentItem={item} />
                )) : null }
            </div>
            
            <div className='sticky bottom-0 px-4 left-0 right-0 backdrop-blur backdrop-filter bg-[#FBFCF8] bg-opacity-90 z-10 h-14'>
                <div className='relative flex flex-col items-center justify-center h-full'>
                    <input
                        type="text" name="new-comment-field" id="new-comment-field" placeholder="Enter Comment..."
                        value={text} onChange={(e) => setText(e.target.value)} required
                        className="block w-full py-2 px-4 rounded-xl border border-[#EEEEEE] bg-inherit text-xs text-black font-normal placeholder:text-[#939393] focus:ring-0 focus:shadow-none"                            
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onSubmitComment();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
