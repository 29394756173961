import * as React from 'react';

export const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);

    function handleWindowSizeChange(): void {
        setScreenWidth(window.innerWidth);
    }
    
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return { screenWidth };
}

export const useFullScreenStatus = () => {
    const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
  
    React.useEffect(() => {
      const handleFullscreenChange = () => {
        setIsFullscreen(!!document.fullscreenElement);
      };
  
      document.addEventListener('fullscreenchange', handleFullscreenChange);
  
      return () => {
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }, []);
  
    return isFullscreen;
};
