import React from "react";

import Constants from "Libraries/Constants";
import { NavLink } from 'react-router-dom';
import { Images } from "Libraries/Images";
import Profile from 'Screens/Profile/Profile';
import { useSelector } from "react-redux";
import { IReducers } from "Libraries/State.Interfaces";
import { useScreenWidth } from "Hooks/UseScrenWidth";

export default function SidePanel({ isSideBarOpen, isProfile, setOpen }: { isSideBarOpen: boolean; isProfile: boolean; setOpen: (f: boolean) => void; }): JSX.Element {

    const { user } = useSelector((state: IReducers) => state.loginReducer);
    const { screenWidth } = useScreenWidth();

    const [iconState, setIconState] = React.useState<boolean>(true);

    React.useEffect(() => {
        setTimeout(() => {
            setIconState(isSideBarOpen);
        }, 200);
    }, [isSideBarOpen]);

    return(
        <div>
            {/* Static sidebar for desktop */}
            <div className={`xl:fixed xl:inset-y-0 lg:fixed lg:inset-y-0 absolute ${(screenWidth < 640 && !iconState) ? 'bg-transparent' : 'bottom-0 bg-[#FFFFFF] border border-[#F5F5F5]'} top-0 left-0 z-20 flex ${isSideBarOpen ? 'w-60' : 'w-16 md:w-20 lg:w-20 xl:w-20'} flex-col transition-all duration-200`}>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto pb-4">
                    <div className="flex h-16 shrink-0 items-center justify-center">
                        <img
                            className={`cursor-pointer w-28 md:w-36 lg:w-36 xl:w-36 mt-3 ${!iconState && 'hidden'}`} src={Images.completeLogo }
                            alt="WA Logo" onClick={() => setOpen(!isSideBarOpen)}
                        />
                        <img
                            className={`cursor-pointer w-12 ${iconState && 'hidden'}`} src={Images.logo} alt="WA Logo" onClick={() => setOpen(!isSideBarOpen)}
                        />                         
                    </div>
                    <div className={`mt-5 ${(screenWidth < 640 && !iconState) ? 'hidden' : 'flex flex-1 flex-col'} space-y-1`}>
                        <nav className="flex-1 space-y-1">
                            { isProfile ? <Profile /> : Constants.navigation(user?.company_id?.company_name === 'Wizard Analytics').filter(f => f.condition).map((item, index) => (
                                <NavLink
                                    key={item.name} to={item?.path}
                                    className={({ isActive }) =>
                                        `group flex items-center ${isSideBarOpen ? 'pl-4' : 'justify-center'} transition-all duration-200 
                                        text-sm md:text-base lg:text-base xl:text-base
                                        cursor-pointer hover:text-[#757575] hover:bg-transparent h-12 ${isActive ? 'text-[#1391CE] font-medium border-r-4 border-[#1391CE] rounded-sm' : 'text-[#757575]'}
                                        ${index === Constants.navigation(user?.company_id?.company_name === 'Wizard Analytics').filter(f => f.condition).length - 1 ? 'absolute left-0 bottom-0 right-0' : ''}
                                    `}
                                >
                                    { isSideBarOpen ? <>
                                        <item.icon className="mr-3 h-4 w-4 md:h-5 md:w-5 lg:h-5 lg:w-5 xl:h-5 xl:w-5 flex-shrink-0" aria-hidden="true" />
                                        {item.name}
                                    </> : (
                                        <item.icon className="h-4 w-4 md:h-6 md:w-6 lg:h-6 lg:w-6 xl:h-6 xl:w-6 flex-shrink-0" aria-hidden="true" />
                                    )}                                    
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
};
