import React, {Fragment, useState} from 'react'

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';
import CommonMethodsUI from "Libraries/CommonMethodsUI";
import { Checkbox } from 'Components/Checkbox/Checkbox';
import { ConfirmModal } from 'Components/Modal/Modal';

interface Props {
    arr: { id: number, name: any; condition?: boolean; }[];
    setData: (obj: {id: number; name: any; }) => void;
    styles?: string;
    isSmallIcon?: boolean;
    isLeftAlign?: boolean;
    isValidateViaAlert?: boolean; 
    title: string;
}

const Dropdown:React.FC<Props> = ({ arr, title, setData, styles, isSmallIcon, isLeftAlign, isValidateViaAlert }) => {

    return (
        <Menu as="div" className="relative inline-block text-left w-full">
            {({open}) => (
                <>                            
                    <div>
                        <Menu.Button className={`inline-flex w-full justify-center ${styles}
                            bg-transparent py-1 ${isSmallIcon ? 'text-xs' : 'text-sm'} text-black`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <span className='text-ellipsis'>{title}</span>
                            <ChevronDownIcon className={isSmallIcon ? "h-4 w-4 ml-3": "h-5 w-5 ml-3"} aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment} show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <MenuItems 
                            arr={arr} isLeftAlign={isLeftAlign}
                            isSmallIcon={isSmallIcon} isValidateViaAlert={isValidateViaAlert}
                            onClick={(e: any, item: any)=> {
                                e.stopPropagation();
                                setData(item);
                            }} 
                        />                        
                    </Transition>
                </>
            )}
        </Menu>
    )
};

export const DropdownWithCheckbox:React.FC<any> = ({ menus, placeholder, selections, setSelections, styles, isSmallIcon }) => {
    return (
        <Menu as="div" className="relative inline-block text-left w-full">
            {({open}) => (
                <>                            
                    <div>
                        <Menu.Button className={`inline-flex w-full justify-center ${styles}
                            bg-transparent py-1 ${isSmallIcon ? 'text-xs' : 'text-sm'} text-black`}
                        >
                            <span className='text-ellipsis'>{placeholder}</span>
                            <ChevronDownIcon className={isSmallIcon ? "h-4 w-4 ml-3": "h-5 w-5 ml-3"} aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment} show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items 
                            className="absolute left-0 mt-1 ml-1 right-0 origin-top-right rounded-md bg-white 
                            shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none h-40 remove-scrollbar overflow-y-auto"
                            style={{zIndex: 99}}
                        >
                            <div className="py-1">
                                {Object.entries(menus as {[key: string]: any[]}).map(([key, value]) => (
                                    <Menu.Item key={key}>
                                        {({ active }) => (
                                            <div className={`block p-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}>
                                                <span className="font-medium mb-2 block"> {key} </span>
                                                { value.map((v: string) => (
                                                    <Checkbox 
                                                        title={v} checked={selections[key].includes(v)} 
                                                        className="cursor-pointer ml-2 py-1" 
                                                        setChecked={(f) => setSelections(key, f, v)}
                                                    />
                                                )) }
                                            </div>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
};

export const CustomMenu = ({ children, title, value, onRemove }: { children: any | JSX.Element; title: string; value: string; onRemove: () => void; }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({open}) => (
                <>
                    <div>
                        <Menu.Button className="inline-flex gap-x-1.5 mr-3 items-center justify-center px-4 rounded-lg bg-[#0991CD] hover:bg-opacity-60 transition-all duration-200 h-8 md:h-10 lg:h-10 xl:h-10 text-xs font-normal text-white shadow-sm focus-visible:outline">
                            {value ? value : title}
                            { value ? (
                                <XMarkIcon onClick={(e) => {
                                    e.stopPropagation();
                                    onRemove();
                                }} className="-mr-1 h-7 w-4 pt-0.5" aria-hidden="true" />
                            ) : (
                                <ChevronDownIcon className="-mr-1 h-7 w-4 pt-0.5" aria-hidden="true" />
                            )}                            
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment} show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        {children}
                    </Transition>
                </>
            )}
        </Menu>
    );
}

export const MenuItems = React.forwardRef((
    props: { arr: { id: number, name: any; condition?: boolean; }[]; isLeftAlign?: boolean; isValidateViaAlert?: boolean; isSmallIcon?: boolean; onClick: (e: any, item: { id: number, name: any; condition?: boolean; }) => void; }, ref) => {
    const { isSmallIcon, arr, onClick, isLeftAlign, isValidateViaAlert } = props;

    if (arr.length && arr[0]?.id !== undefined) {
        return (
            <Menu.Items 
                className={`absolute ${isLeftAlign ? 'left-0' : 'right-0'} mt-2 w-48 max-h-48 overflow-y-auto remove-scrollbar origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                style={{zIndex: 99}} ref={ref as any}
            >
                <div className="py-1">
                    { arr.map((item: any) => (
                        <Menu.Item key={item.id}>
                            {({ active }) => (
                                isValidateViaAlert ? (
                                    <ConfirmModal isDisabledConfirm={false} message='Are you sure you want to proceed?' onConfirm={(e) => onClick(e, item)}>
                                        <div data-testid="menuitem" className={CommonMethodsUI.classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            `block px-3 py-2 ${isSmallIcon ? 'text-xs' : 'text-sm'} cursor-pointer`)}                                            
                                        >
                                            {item.name}
                                        </div>    
                                    </ConfirmModal>
                                ) : (
                                    <div data-testid="menuitem" className={CommonMethodsUI.classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        `block px-3 py-2 ${isSmallIcon ? 'text-xs' : 'text-sm'} cursor-pointer`)}
                                        onClick={(e)=> onClick(e, item)}
                                    >
                                        {item.name}
                                    </div>
                                )
                            )}
                        </Menu.Item>
                    )) }
                </div>
            </Menu.Items>
        );
    }
    
    return <Menu.Items ref={ref as any} />;
});

export default Dropdown;
