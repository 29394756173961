import React, {useState} from 'react';

// Components
import {Button} from "Components/Button/Button";
import {SpinnerLoader} from "Components/Loader/Loader";
import {Images} from "Libraries/Images";

// Icons
import ApiRequest from "Services/ApiRequest";
import {PasswordField} from "Components/Password/Password";

// Interface
interface Props {
    setOpen: (f: boolean) => void;
}

const ChangePasswordScreen:React.FC<Props> = ({ setOpen }) => {

    const [passwordCredentials, setPasswordCredentials] = useState<{ oldpassword: string; newpassword: string; }>({ oldpassword: '', newpassword: '' });
    const [error, setError] = useState<{ oldpasswordError: string; newpasswordError: string; }>({ oldpasswordError: '', newpasswordError: '' });
    const [isPasswordLoading, setIsPasswordLoading] = useState<boolean>(false);
    const [isPasswordError, setIsPasswordError] = useState<string>('');

    // TODO: Request the reset password if user forgot their password
    async function resetPassword(): Promise<void> {
        const { oldpassword, newpassword } = passwordCredentials;

        try {
            await ApiRequest.changePasswordRequest({old_password: oldpassword, new_password: newpassword});
            setPasswordCredentials({ oldpassword: '', newpassword: '' });
            setIsPasswordError('Password Changed Successfully'); 
            setIsPasswordLoading(false);
        }
        catch (error: any) {
            setIsPasswordError(error ? error?.old_password[0] : 'Issue while changing password');
            setIsPasswordLoading(false);
        }
    };

    return (
        <div>
            <div className="flex flex-row items-center justify-center mb-6">
                <img
                    className="h-12 w-auto"
                    src={Images.logo}
                    alt="Wizard Analytics"
                />
                <div className="ml-2 text-center text-2xl font-bold tracking-tight text-gray-800">Update Password</div>
            </div>
            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-4">
                    Current Password
                </label>
                <div >
                    <PasswordField
                        id="Cpassword"
                        name="Cpassword"
                        value={passwordCredentials.oldpassword}
                        onChange={(e: any)=> setPasswordCredentials({ ...passwordCredentials, oldpassword: e.target.value })}
                        keyPress={(e: any) => e.key === 'Enter' && resetPassword()}
                        autoComplete={'off'}
                        placeholder="Please Enter Current Password"
                        className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 ${error.oldpasswordError ? 'border-red-500' : 'border-gray-300'} 
                                        placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                    <div className="mt-1 text-sm text-red-500 h-1">
                        {error.oldpasswordError ?? error.oldpasswordError}
                    </div>
                </div>
            </div>

            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-4">
                    New Password
                </label>
                <div >
                    <PasswordField
                        id="Npassword"
                        name="Npassword"
                        value={passwordCredentials.newpassword}
                        onChange={(e: any)=> setPasswordCredentials({ ...passwordCredentials, newpassword: e.target.value })}
                        keyPress={(e: any) => e.key === 'Enter' && resetPassword()}
                        autoComplete={'off'}
                        placeholder="Please Enter New Password"
                        className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 ${error.newpasswordError ? 'border-red-500' : 'border-gray-300'} 
                                        placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                    <div className="mt-1 text-sm text-red-500 h-1">
                        {error.newpasswordError ?? error.newpasswordError}
                    </div>
                </div>
            </div>

            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mt-4">
                    Confirm New Password
                </label>
                <div >
                    <PasswordField
                        id="Npassword"
                        name="Npassword"
                        value={passwordCredentials.newpassword}
                        onChange={(e: any)=> setPasswordCredentials({ ...passwordCredentials, newpassword: e.target.value })}
                        keyPress={(e: any) => e.key === 'Enter' && resetPassword()}
                        autoComplete={'off'}
                        placeholder="Please Confirm New Password"
                        className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 ${error.newpasswordError ? 'border-red-500' : 'border-gray-300'} 
                                        placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                    />
                    <div className={`mt-1 text-sm ${isPasswordError.includes('Successfully') ?  'text-green-500' : 'text-red-500'} h-1`}>
                        {isPasswordError ? isPasswordError : error.newpasswordError ?? error.newpasswordError}
                    </div>
                </div>
            </div>

            <div className="py-3 sm:flex sm:flex-row-reverse">
                <Button
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700  sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={(e) => resetPassword()}
                    disabled={isPasswordLoading}
                    title={isPasswordLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
                />
                <Button
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    title={'Cancel'}
                />
            </div>

        </div>
    )
};

export default ChangePasswordScreen;
