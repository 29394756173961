import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import NetworkStatusIndicator from 'Hooks/UseNavigator';

import reportWebVitals from './reportWebVitals';

import {BrowserRouter as Router} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { configureStore } from './Redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import {ErrorBoundary} from "react-error-boundary";
import ApiRequest from "./Services/ApiRequest";
import Toaster from 'Components/Toaster/Toaster';

// CSS Files
import './index.css';
import '../src/Assets/Styles/Tailwind.css';
import '../src/Assets/Styles/core.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-circular-progressbar/dist/styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const store = configureStore();
const persistor = persistStore(store);

const ErrorFallback = ( error: any ) => {
  // window.location.reload();
};

async function myErrorHandler(error: Error, info: {componentStack: string}): Promise<void> {
    // Do something with the error E.g. log to an error logging client here
    try {
        // await ApiRequest.createLogRequest({component_stack_message: info.componentStack, exception_message: error?.message.toString()});
        console.log('Successfully Wrote Log');
    }
    catch (err) {
        console.error('error Boundary', error?.message.toString()); console.error('error Info', info);
    }
}

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
              <Router>
                    <ErrorBoundary
                        // @ts-ignore
                        FallbackComponent={(e)=>ErrorFallback(e)}
                        onError={(error, errorInfo) => myErrorHandler(error, errorInfo)}
                    >
                        <NetworkStatusIndicator />
                        <App />
                        <Toaster />
                    </ErrorBoundary>
              </Router>
          </PersistGate>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//     "build:production": "npm run build:css && env-cmd -f ./.env.production react-scripts build && gzip -k build/static/js/main.*.js.map"
