import { SIDEBAR_ON_OFF, SET_PASSWORD_RULES, APP_LOADER } from "Redux/Types";
import { IActivityState, IAction } from "Libraries/State.Interfaces";

const initialState: IActivityState = {
    sideBarState: false,
    passwordRules: [],
    loader: false
};

export const ActivityReducer = (state = initialState, action: IAction): IActivityState => {

    const { payload, type } = action;
    switch(type){
        case SIDEBAR_ON_OFF: {
            return {
                ...initialState,
                sideBarState: payload
            };
        }
        case SET_PASSWORD_RULES: {
            return {
                ...initialState,
                passwordRules: payload
            };
        }
        case APP_LOADER: {
            return {
                ...initialState,
                loader: payload
            };
        }
        default:
            return state;
    }
};
