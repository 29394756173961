import * as React from 'react';
import { useLocation } from 'react-router-dom';

export default function DocumentTitle({ title, children }: { title: string; children: JSX.Element | any }): JSX.Element {
    const { pathname } = useLocation();

    React.useEffect(() => {
        document.title = title;
    }, [title, pathname]);

    return children;
}