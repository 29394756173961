import * as React from 'react';
import { toast } from 'react-toastify';

const getOnLineStatus = (): boolean =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;

export const useNavigatorOnLine = () => {
    const [status, setStatus] = React.useState<boolean>(getOnLineStatus());

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    React.useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return status;
};

const NetworkStatusIndicator = () => {
    const isOnline = useNavigatorOnLine();
    const firstUpdate = React.useRef(true);

    React.useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (isOnline) {
            toast.clearWaitingQueue();
            toast.dismiss();
            // toast.success('You are back online!', {
            //     autoClose: 1000, closeOnClick: true, closeButton: true
            // });
        }
        else if (!isOnline) {
            toast.warning('You are currently offline', {
                autoClose: false, closeOnClick: false, closeButton: false, draggable: false
            });
        }
    }, [isOnline]);

    return null;
};

export default NetworkStatusIndicator;