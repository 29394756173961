import * as React from "react";

import './App.css';

import { Routes, Route } from "react-router-dom";
import { Paths } from "Libraries/Route";
import { Redirect } from "Routes/Redirect";
import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "Services/ApiRequest";

import UploadFileViaEmail from "Screens/Dashboard/UploadFileViaEmail";
import ReportedOccupancy from 'Screens/Reported/Occupancy';
import ReportedConstruction from 'Screens/Reported/Construction';
import StpDataForm from "Screens/Stp/DataForm";
import { setPasswordRulesAction } from "Redux/Action";
import { MainLoader } from "Components/Loader/Loader";
import { ILoginState, IReducers } from "Libraries/State.Interfaces";
import { TokenService } from "Libraries/TokenService";
import MasterLayout from "Hoc/MasterLayout";
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import Methods from "Libraries/CommonMethodsUI";

const Users = React.lazy(() => import("Screens/Users/Users"));
const Invoice = React.lazy(() => import("Screens/Invoice/Invoice"));
const Subscriptions = React.lazy(() => import('Screens/Subscriptions/Subscriptions'));
const TemplateHistory = React.lazy(() => import('Screens/TemplateHistory/TemplateHistory'));
const DashboardScreen = React.lazy(() => import('Screens/Dashboard/Content'));
const VersionList = React.lazy(() => import('Screens/CnVersions/VersionList'));
const CompanyScreen = React.lazy(() => import('Screens/Company/Content'));
const Triage = React.lazy(() => import('Screens/Triage/Triage'));
const LoginScreen = React.lazy(() => import('Screens/Login/Content'));
const SignUpScreen = React.lazy(() => import('Screens/SignUp/Content'));
const ForgotPasswordScreen = React.lazy(() => import('Screens/ForgotPassword/Content'));
const ForgotPasswordConfirmScreen = React.lazy(() => import('Screens/ForgotPasswordConfirm/Content'));
const Stp = React.lazy(() => import('Screens/Stp/Stp'));
const ReportAnalytics = React.lazy(() => import('Screens/Report/ReportAnalytics'));
const ReportAnalyticsBE = React.lazy(() => import('Screens/Report/ReportAnalyticsBE'));
const Settings = React.lazy(() => import('Screens/Settings/Settings'));

function SuspenseRoute({ children, isLoggedIn, title }: { 
  children: JSX.Element | React.ReactNode, isLoggedIn: boolean; title?: string; 
}) {
  const hostname = window.location.hostname;
  const basename: string = hostname.split('.')[0];

  return (
    <React.Suspense fallback={<MainLoader isLoggedIn={isLoggedIn} />}>
      <DocumentTitle title={isLoggedIn ? `UW / ${Methods.capitalize(basename)}` : title as string}>
        {children}
      </DocumentTitle>
    </React.Suspense>
  )
}

function App(): JSX.Element {

  const dispatch = useDispatch();
  const state: ILoginState = useSelector((state: IReducers) => state.loginReducer);

  React.useEffect(() => {
    getPasswordRules();
  }, []);

  async function getPasswordRules(): Promise<void> {
    const { data } = await ApiRequest.getPasswordRules();
    dispatch(setPasswordRulesAction(data.password_rules));
  }

  if(!state?.isLoggedIn || !TokenService.getAccessToken()) {
    return (
      <Routes>
        <Route path={'/'} element={<Redirect to={Paths.login} />} />
        <Route path={Paths.login} element={<SuspenseRoute title="Sign In" isLoggedIn={false}> <LoginScreen /> </SuspenseRoute>} />
        <Route path={Paths.signup} element={<SuspenseRoute title="Sign Up" isLoggedIn={false}> <SignUpScreen /> </SuspenseRoute>} />
        <Route path={Paths.forgotPassword} element={<SuspenseRoute title="Forgot Password" isLoggedIn={false}> <ForgotPasswordScreen /> </SuspenseRoute>} />
        <Route path={Paths.forgotPasswordConfirm} element={<SuspenseRoute title="Confirm Forgot Password" isLoggedIn={false}> <ForgotPasswordConfirmScreen /> </SuspenseRoute>} />

        <Route path="*" element={<Redirect to={Paths.login} />} />
      </Routes>
    )
  }

  return (
    <MasterLayout dispatch={dispatch} userEmail={state?.user?.email ?? ""}>
      {({ setMainTitle }: { setMainTitle: (t: string) => void; }) => (
        <Routes>
          <Route path={Paths.home}>
            <Route index element={<SuspenseRoute isLoggedIn={true}> <DashboardScreen setMainTitle={setMainTitle} /> </SuspenseRoute>} />
            <Route 
              path={Paths.controlNumber} element={<SuspenseRoute isLoggedIn={true}><VersionList setMainTitle={setMainTitle} /></SuspenseRoute>} 
            />
            <Route path={`${Paths.controlNumber}/${Paths.version}${Paths.email}`} element={<SuspenseRoute isLoggedIn={true}><UploadFileViaEmail bottom={2} /></SuspenseRoute>} />
          </Route>
          
          <Route path={`/${Paths.controlNumber}/${Paths.version}${Paths.report}`} element={<SuspenseRoute isLoggedIn={true}><ReportAnalytics setMainTitle={setMainTitle} /></SuspenseRoute>} />
          <Route path={`/${Paths.controlNumber}/${Paths.version}${Paths.dashboard}`} element={<SuspenseRoute isLoggedIn={true}><ReportAnalyticsBE setMainTitle={setMainTitle} /></SuspenseRoute>} />

          <Route path={Paths.reportedConstruction} element={<SuspenseRoute title="Reported Construction" isLoggedIn={true}><ReportedConstruction /></SuspenseRoute>} />
          <Route path={Paths.reportedOccupancy} element={<SuspenseRoute title="Reported Occupancy" isLoggedIn={true}><ReportedOccupancy /></SuspenseRoute>} />
          <Route path={Paths.templateHistory} element={<SuspenseRoute title="Template History" isLoggedIn={true}><TemplateHistory /></SuspenseRoute>} />
          <Route path={Paths.triage} element={<SuspenseRoute title="Triage" isLoggedIn={true}><Triage /></SuspenseRoute>} />
          <Route path={Paths.users} element={<SuspenseRoute title="Users" isLoggedIn={true}><Users /></SuspenseRoute>} />
          <Route path={Paths.subscription} element={<SuspenseRoute title="Subscriptions" isLoggedIn={true}><Subscriptions /></SuspenseRoute>} />
          <Route path={Paths.invoice} element={<SuspenseRoute title="Invoice" isLoggedIn={true}><Invoice /></SuspenseRoute>} />
          {/* Stp ==>> Straight Through Processing */}
          <Route path={Paths.stp}>
            <Route index element={<SuspenseRoute title="Upload" isLoggedIn={true}><Stp /></SuspenseRoute>} />
            <Route path={Paths.controlNumber} element={<SuspenseRoute title="Upload" isLoggedIn={true}><Stp /></SuspenseRoute>} />
            <Route path={Paths.form} element={<SuspenseRoute title="Upload Data Form" isLoggedIn={true}><StpDataForm /></SuspenseRoute>} />
          </Route>
          <Route path={Paths.companies} element={<SuspenseRoute title="Companies" isLoggedIn={true}><CompanyScreen /></SuspenseRoute>} />
          <Route path={Paths.settings} element={<SuspenseRoute title="Settings" isLoggedIn={true}><Settings setMainTitle={setMainTitle} /></SuspenseRoute>} />
          
          <Route path="*" element={<Redirect to={Paths.home} />} />
        </Routes>
      )}
    </MasterLayout>
  );
}

export default App;
