import * as React from 'react';

import { DateRangePicker, Calendar } from "react-date-range";
import Methods from 'Libraries/CommonMethodsUI';
import { XMarkIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition } from '@headlessui/react';
import { format } from 'date-fns'; // For date formatting
import { useScreenWidth } from 'Hooks/UseScrenWidth';

export interface ISelectionRange{
    startDate: Date | undefined;
    endDate: Date | undefined;
    key: string;
}

interface IDateTimeRangePicker {
    selectionRange: ISelectionRange;
    setSelectionRange: (arg: ISelectionRange | undefined) => void;
}

const DateTimeRangePicker: React.FC<IDateTimeRangePicker> = ({ selectionRange, setSelectionRange }) => {

    const { screenWidth } = useScreenWidth();

    const handleSelect = React.useCallback((ranges: { selection?: ISelectionRange; range1?: ISelectionRange }) => {
        if (ranges?.selection)
            setSelectionRange(ranges.selection);
        if (ranges?.range1) 
            setSelectionRange({ ...ranges?.range1, key: "selection" });
    }, []);

    return (
        <Listbox value={selectionRange?.startDate ? `${Methods.getDateByFormat(selectionRange?.startDate as any, "MMMM Do YYYY")} - ${Methods.getDateByFormat(selectionRange?.endDate as any, "MMMM Do YYYY")}` : 'Date Range'}>
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                    <div className="relative">
                        <Listbox.Button
                            className="inline-flex items-center justify-center px-4 rounded-lg bg-[#0991CD] hover:bg-opacity-60 transition-all duration-200 h-8 md:h-10 lg:h-10 xl:h-10 text-xs font-normal text-white shadow-sm focus-visible:outline"
                        >
                            <span>{ selectionRange?.startDate ? `${Methods.getDateByFormat(selectionRange?.startDate as any, "MMMM Do YYYY")} - ${Methods.getDateByFormat(selectionRange?.endDate as any, "MMMM Do YYYY")}` : 'Date Range' }</span>
                            { !selectionRange?.startDate ? null : (
                                <XMarkIcon className="-mr-0.5 ml-1 h-4 w-4" aria-hidden="true" onClick={() => setSelectionRange({
                                    endDate: undefined, key: '', startDate: undefined
                                })} />
                            )}
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={React.Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute left-0 z-30 mt-2 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none">
                                <DateRangePicker
                                    ranges={[selectionRange]} months={screenWidth > 991 ? 2 : 1}
                                    onChange={(ranges: any) => handleSelect(ranges)}
                                    startDatePlaceholder="Start Date" endDatePlaceholder='End Date'
                                    moveRangeOnFirstSelection={false}
                                    direction="horizontal"
                                    staticRanges={screenWidth > 991 ? undefined : []}
                                />
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export const DatePicker: React.FC<{ 
    label: string, selection: Date, setSelection: (d: Date | null) => void; buttonStyles: string;
}> = ({ label, selection, setSelection, buttonStyles }) => {

    return (
        <Listbox value={selection ? `${format(selection, 'dd/MM/yyyy')}` : 'Date Selection'}>
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                    <div className="relative">
                        <Listbox.Button
                            className={`transition-all inline-flex items-center duration-200 focus-visible:outline ${buttonStyles}`}
                        >
                            <span>{ selection ? `${format(selection, 'dd/MM/yyyy')}` : label }</span>
                            { !selection ? (
                                <CalendarIcon className="-mr-0.5 ml-1 h-4 w-4" aria-hidden="true" />
                            ) : (
                                <XMarkIcon className="-mr-0.5 ml-1 h-4 w-4" aria-hidden="true" onClick={(e) => {
                                    e.stopPropagation();
                                    setSelection(null);
                                }} />
                            )}
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={React.Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute left-0 z-30 mt-2 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none">
                                <Calendar
                                    date={selection}
                                    onChange={(d) => setSelection(d)}
                                    startDatePlaceholder="Select Date"
                                />
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default DateTimeRangePicker;