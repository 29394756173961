import * as React from 'react';

import { PolicyTermsSection, LocationDeductiblesSection, SubLimitAreaDeductiblesSection, Divider } from './Sections';
import withAuthentication from 'Hoc/WithAuthentication';
import { Paths } from 'Libraries/Route';
import { IDeductible, IPolicyTermLayer } from 'Libraries/Interfaces';

function StpDataForm(): JSX.Element {
    const initialLayer: IPolicyTermLayer = { attachment: '', deductibleAmount: "", deductibleType: "", partOf: "", perils: [], policyLimit: "" };
    const [policy, setPolicy] = React.useState<{ info: { [key: string]: any; }; layers: IPolicyTermLayer[] }>({
        info: {}, layers: [initialLayer]
    });

    const initialLayer1: IDeductible = { perils: [], geography: { state: [], county_tier: [], hu_zone: [], eq_zone: [] }, deductibleType: "", deductible: "", deductibleA: "", deductibleB: "", deductibleC: "", deductibleD: "" };
    const [locationDeductibles, setLocationDeductibles] = React.useState<IDeductible[]>([initialLayer1]);

    const initialLayer2: IDeductible = { perils: [], geography: { state: [], county_tier: [], hu_zone: [], eq_zone: [] }, deductibleType: "", deductible: "" };
    const [subLimitDeductibles, setSubLimitDeductibles] = React.useState<IDeductible[]>([initialLayer2]);
    
    return (
        <div className='h-full overflow-hidden px-3 sm:px-4 lg:px-6'>
            <div className='max-h-[85vh] remove-scrollbar overflow-y-auto'>
                <PolicyTermsSection 
                    info={policy.info} layers={policy.layers} 
                    onRemoveLayer={(ind: number) => setPolicy({ ...policy, layers: policy.layers.filter((_, i) => i !== ind) })}
                    onAddLayer={() => setPolicy({ ...policy, layers: [ ...policy.layers, initialLayer ] })}
                    setPolicyInfo={(k: string, v: any) => setPolicy({ ...policy, info: { ...policy.info, [k]: v } })} 
                    setLayerValues={(ind: number, key: string, value: any) => {
                        const arr: IPolicyTermLayer[] = [ ...policy.layers ];
                        arr[ind] = { ...arr[ind], [key]: value };
                        setPolicy({ ...policy, layers: arr });
                    }}
                />
                <Divider label="Continue" />
            
                <LocationDeductiblesSection 
                    locationDeductibles={locationDeductibles} 
                    setLocationDeductibles={(ind: number, key: string, value: any) => {
                        const arr: IDeductible[] = [ ...locationDeductibles ];
                        arr[ind] = { ...arr[ind], [key]: value };
                        setLocationDeductibles(arr);
                    }}
                    onRemoveLayer={(ind: number) => setLocationDeductibles(locationDeductibles.filter((_, i) => i !== ind))}
                    onAddLayer={() => setLocationDeductibles([ ...locationDeductibles, initialLayer1 ])}
                />
                <Divider label="Continue" />

                <SubLimitAreaDeductiblesSection 
                    subLimitDeductibles={subLimitDeductibles} 
                    setSubLimitDeductibles={(ind: number, key: string, value: any) => {
                        const arr: IDeductible[] = [ ...subLimitDeductibles ];
                        arr[ind] = { ...arr[ind], [key]: value };
                        setSubLimitDeductibles(arr);
                    }}
                    onRemoveLayer={(ind: number) => setSubLimitDeductibles(subLimitDeductibles.filter((_, i) => i !== ind))}
                    onAddLayer={() => setSubLimitDeductibles([ ...subLimitDeductibles, initialLayer1 ])}
                />
                <Divider label="End" />
            </div>
        </div>
    );
}

export default withAuthentication(StpDataForm, Paths.login, 10);