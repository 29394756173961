export const Images = {
    authBg: require('../Assets/Images/auth-bg.png'),
    logo: require('../Assets/Images/logo.png'),
    tick: require('../Assets/Images/tick.png'),
    pdfIcon: require('../Assets/Images/pdf_icon.png'),
    excelIcon: require('../Assets/Images/excel_icon.png'),
    completeLogo: require('../Assets/Images/complete_logo.png'),
    textFileIcon: require('../Assets/Images/text-file-icon.png'),
    imageIcon: require('../Assets/Images/image-icon.png'),
    fileErrorIcon: require('../Assets/Images/file-error.png'),
    reportLine: require('../Assets/Images/report-line.png'),
    messageDots: require('../Assets/Images/message-dots.png')
}
