export enum statusEnum {
    Submitted,
    Assigned,
    In_Process,
    Exposures_Ready,
    Modeled,
    Quoted,
    Bound,
    Canceled
}

export enum RolesEnum {
    SUPER_ADMIN,
    COMPANY_ADMIN,
    CAT_MODELER,
    UNDERWRITER
}

export enum popupMessageEnum{
    success = 'success',
    error = 'error',
    info = 'info'
}

export enum modalScreensEnum{
    password,
    profile
}

export enum sortColumnEnum{
    Status= 'Status',
    Assigned_To= 'Assigned To',
    SOV= 'SOV',
    Company= 'Company'
}

export enum PerilsEnumerator {
    EQ = "EQ",
    FF = "FF",
    FL = "FL",
    HU = "HU",
    TO = "TO",
    TR = "TR",
    WF = "WF",
    WS = "WS",
}
